<template>
  <app-container title="Whatsapp status" header="Whatsapp status">
    <div>
      <app-snackbar v-model="showSnackbar" :text="snackbarText"></app-snackbar>
      <app-list-with-heading
        :headerText="whatToDo.headerText"
        :descriptions="whatToDo.descriptions"
      >
      </app-list-with-heading>
      <app-toggle-bar :chips="chips" v-model="selected"> </app-toggle-bar>
      <div class="post-card-container">
        <app-post-card
          v-for="task in getWhatsappArray(WhatsappStatusList)"
          :key="task.postId"
          :post="task"
          :postBtnClicked="postBtnClicked"
          @click.native="goToWhatsappPost(task.postId)"
        >
        </app-post-card>
      </div>
    </div>
  </app-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Container from "@/components/shared/Container.vue";
import ListWithHeading from "@/components/shared/ListWithHeading.vue";
import PostCard from "@/components/postcard/PostCard.vue";
import ToggleBar from "@/components/toggleBar/togglebar.vue";
import Snackbar from "@/components/shared/Snackbar.vue";

export default {
  computed: {
    ...mapGetters({
      WhatsappStatusList: "whatsappstatustask/getWhatsAppStatusList",
    }),
  },
  data: () => ({
    showSnackbar: false,
    snackbarText: "",
    showStatus: false,
    whatToDo: {
      headerText: "What to do?",
      descriptions: [
        "We have listed you various posts which you can post on your whatsapp status (Instagram, Facebook status too)",
        "Read the instructions before posting the status message",
        "You can put 'Whatsapp status' once in 3 days. Evenings (After 6pm) are better time to post status messages.",
        "Mark the post as 'Posted' once you have posted the status message",

      ],
    },
    chips: ["Yet to post", "Posted"],
    selected: "Yet to post",
  }),
  components: {
    appContainer: Container,
    appListWithHeading: ListWithHeading,
    appPostCard: PostCard,
    appToggleBar: ToggleBar,
    appSnackbar: Snackbar,
  },
  methods: {
    ...mapActions({
      updateCompletedWhatsapp: "user/CompletedWhatsappStatus",
    }),
    goToWhatsappPost(id) {
      this.$router.push({
        name: "whatsappstatuspost",
        params: {
          whatsappstatusid: id,
        },
      });
    },
    getWhatsappArray(whatsappStatusList) {
      if (this.selected === "Posted") {
        return whatsappStatusList.filter(
          (whatsappStatus) => whatsappStatus.completed
        );
      } else {
        return whatsappStatusList.filter(
          (whatsappStatus) => !whatsappStatus.completed
        );
      }
      return whatsappStatusList;
    },
    async postBtnClicked(data, isCompleted) {
      try {
        if (isCompleted) {
          await this.updateCompletedWhatsapp({
            postId: data,
            action: "remove",
          });
        } else {
          await this.updateCompletedWhatsapp({
            postId: data,
          });
        }
        this.showSnackbar = true;
        this.snackbarText = "Updated successfully";
      } catch (err) {
        this.showSnackbar = true;
        this.snackbarText = "Unable to update the post.Please try again";
      }
    },
  },
};
</script>

<style scoped>
.status-switch {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 35px;
}

.status-text {
  display: flex;
  margin: 0 12px;
  font-size: 17px;
}

.post-card-container {
  min-height: 300px;
}
</style>
