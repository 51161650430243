<template>
  <div class="signup-container">
    <div class="sign-up-img-container">
      <img class="sign-up-img" src="@/assets/logo.png" />
    </div>
    <div class="signup-card" ref="form">
      <v-form v-if="showLoginForm" @submit.prevent="signup">
        <div>
          <div class="signup-text">Login</div>
          <v-text-field
            data-test="email-input"
            v-model.trim="email"
            v-validate="'required|email'"
            data-vv-delay="350"
            outlined
            name="email"
            label="Email"
            :error-messages="errors.first('email')"
            autocomplete="email"
            maxlength="192"
          ></v-text-field>
          <v-text-field
            :append-icon="show4 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show4 ? 'text' : 'password'"
            data-test="password-input"
            v-model.trim="password"
            v-validate="'required|length:5,32'"
            outlined
            name="password"
            label="Password"
            :error-messages="errors.first('password')"
            autocomplete="new-password"
            maxlength="32"
            counter
            @click:append="show4 = !show4"
          >
          </v-text-field>
          <div class="btn-container-fgt">
            <!-- color="#2b3c4d" -->
            <v-btn
              color="#1A3263"
              small
              outlined
              elevation="2"
              tile
              @click="forgetPassword"
              >Forget Password</v-btn
            >
          </div>
          <div class="btn-container">
            <v-btn
              color="#1A3263"
              small
              outlined
              elevation="2"
              tile
              @click="toggleForm"
              >New User?</v-btn
            >
            <v-btn color="#1A3263" dark small elevation="2" tile @click="login"
              >Login</v-btn
            >
          </div>
        </div>
      </v-form>
      <v-form v-else @submit.prevent="signup">
        <div>
          <div class="signup-text">Let's get started</div>
          <v-text-field
            data-test="email-input"
            v-model.trim="email"
            v-validate="'required|email'"
            data-vv-delay="350"
            outlined
            name="email"
            label="Email"
            :error-messages="errors.first('email')"
            autocomplete="email"
            maxlength="192"
          ></v-text-field>
          <div class="flag-number">
            <vue-country-code
              @onSelect="onSelect"
              :preferredCountries="['in', 'us', 'gb', 'ca', 'sg', 'au', 'ae']"
            >
            </vue-country-code>
            <v-text-field
              ref="phoneNumber"
              data-test="number-input"
              v-model.trim="phoneNumber"
              v-validate="'required|phone'"
              data-vv-delay="350"
              outlined
              name="phone"
              type="number"
              label="Phone Number"
              :error-messages="errors.first('phone')"
              autocomplete="phone"
            >
            </v-text-field>
          </div>
          <v-text-field
            :append-icon="show4 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show4 ? 'text' : 'password'"
            data-test="password-input"
            v-model.trim="password"
            v-validate="'required|length:5,32'"
            outlined
            name="password"
            label="Password"
            :error-messages="errors.first('password')"
            autocomplete="new-password"
            maxlength="32"
            counter
            @click="show4 = !show4"
          >
          </v-text-field>
          <div class="btn-container">
            <v-btn
              color="#1A3263"
              dark
              small
              outlined
              elevation="2"
              tile
              @click="toggleForm"
              >Already have a account?</v-btn
            >
            <v-btn color="#1A3263" dark small elevation="2" tile @click="signup"
              >Sign Up</v-btn
            >
          </div>
        </div>
      </v-form>
    </div>
    <app-dialog
      v-model="showDialog"
      :dialogDescription="dialogTitle"
      dialogAgreeText="Dismiss"
      v-on:dialog-agreed="dismissClicked"
    ></app-dialog>
  </div>
</template>

<script>
import { Validator } from "vee-validate";
import { mapActions } from "vuex";
import Dialog from "@/components/shared/Dialog.vue";
import router from "@/router";

import { focusToErrorTextField } from "@/utils/focus-to-error-text-field";
export default {
  props: {
    buttonText: {
      type: String,
      default: "Button",
    },
  },
  data: () => ({
    show4: false,
    email: null,
    password: null,
    dialogTitle: "",
    phoneNumber: null,
    countryCode: "00",
    showLoginForm: true,
    showDialog: false,
  }),
  methods: {
    ...mapActions({
      signUpUser: "user/signup",
      loginUser: "user/login",
      forgetPasswordUser: "user/forgetPassword",
    }),
    toggleForm() {
      this.showLoginForm = !this.showLoginForm;
    },
    dismissClicked() {
      this.showDialog = !this.showDialog;
    },
    async signup() {
      if (!(await this.$validator.validateAll())) {
        focusToErrorTextField(this.$refs.form);
        return;
      }
      try {
        await this.signUpUser({
          email: this.email,
          password: this.password,
          phoneNumber: this.countryCode + this.phoneNumber,
        });
      } catch (err) {
        if (err) {
          if (err.code === "auth/email-already-in-use") {
            this.dialogTitle = "Email already exist. please try login";
            this.showDialog = true;
          } else if (err.code === "auth/user-not-found") {
            this.dialogTitle =
              "Email id not found. please signup first or try with different email id";
            this.showDialog = true;
          } else if (err.code === "auth/too-many-requests") {
            this.dialogTitle =
              "Too many requests. please try again after sometime";
            this.showDialog = true;
          } else if (err.code === "user not available") {
            router.replace("/accessrequest");
          } else {
            this.dialogTitle = "Something went wrong. please try again";
            this.showDialog = true;
          }
        } else if (err == "document doesn't exist") {
          router.replace("/accessrequest");
        } else {
          this.dialogTitle = "Something went wrong. please try again";
          this.showDialog = true;
        }
      }
    },
    async forgetPassword() {
      if (!(await this.$validator.validate("email"))) {
        return;
      }
      try {
        await this.forgetPasswordUser({
          email: this.email,
        });
        this.dialogTitle = "Success!! Check your email for a reset link";
        this.showDialog = true;
      } catch (err) {
        if (err && err.code === "auth/user-not-found") {
          this.dialogTitle =
            "Your email ID is not in our database. Put correct email ID or register as a new user.";
          this.showDialog = true;
        } else {
          this.dialogTitle =
            "Some problem while sending reset link. Please try again";
          this.showDialog = true;
        }
      }
    },
    async login() {
      if (!(await this.$validator.validateAll())) {
        focusToErrorTextField(this.$refs.form);
        return;
      }
      try {
        await this.loginUser({
          email: this.email,
          password: this.password,
        });
      } catch (err) {
        if (err) {
          if (err.code === "auth/wrong-password") {
            this.dialogTitle = "Email id or Password is incorrect";
            this.showDialog = true;
          } else if (err.code === "auth/user-not-found") {
            this.dialogTitle =
              "Email id not found. please signup first or try with different email id.";
            this.showDialog = true;
          } else if (err.code === "auth/too-many-requests") {
            this.dialogTitle =
              "Too many requests. please try again after sometime";
            this.showDialog = true;
          } else if (err == "document doesn't exist") {
            router.replace("/accessrequest");
          } else {
            this.dialogTitle = "Something went wrong. please try again";
            this.showDialog = true;
          }
        } else {
          this.dialogTitle = "Something went wrong. please try again";
          this.showDialog = true;
        }
      }
    },
    onSelect({ name, iso2, dialCode }) {
      this.countryCode = dialCode;
    },
  },
  components: {
    appDialog: Dialog,
  },
};
</script>

<style scoped>
.btn-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.signup-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
}
.signup-card {
  background-color: #fffffe;
  width: 100%;
  min-height: 80%;
  padding: 30px;
  flex: 1;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
}
.signup-text {
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 35px;
}
.flag-number {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.sign-up-img {
  width: 167px;
  justify-content: center;
  display: flex;
  margin: 26px 0;
  display: flex;
}
.sign-up-img-container {
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
}
.vue-country-select {
  margin-right: 10px;
  height: 56px;
}
</style>
<style>
.vue-country-select .dropdown-list {
  width: 280px !important;
}
</style>
<style scoped>
.v-application .primary--text,
.v-application .primary--text .v-text-field__slot {
  color: #1a3263 !important;
  caret-color: #1a3263 !important;
}
</style>
