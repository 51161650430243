import * as fb from '@/common/config';

export default {
  namespaced: true,
  getters: {
    getWhatsAppStatusList: state => {
      return state.whatsAppStatusList;
    },
    getWhatsAppStatusPost: state => {

      return (id) => {
        return state.whatsAppStatusList.find(whatsapp => whatsapp.postId === id);
      }

    }
  },
  actions: {
    async FETCHWHATSAPPSTATUSLIST({
      commit
    },payload) {


      const whatsappStatusList = [];
      // try{
      const snapshot = await fb.whatsappstatusCollection.where("postStatus", "==", "active").get()

      snapshot.docs.forEach(status => {
        const whatsappstatus = status.data()
        if(payload && payload.includes(whatsappstatus.postId)) {
          whatsappstatus.completed= true
        }else {
          whatsappstatus.completed= false
        }
        whatsappStatusList.push(whatsappstatus)
      })
      commit('SET_WHATSAPPLIST', whatsappStatusList)
    },
    async UPDATEWHATSAPPSTATUSLIST({
      commit,
      getters
    },payload) {
      const whatsappStatusList = getters.getWhatsAppStatusList
      const whatsappStatusArray = []
      whatsappStatusList.forEach(whatsappStatus => {
        if(payload && payload.includes(whatsappStatus.postId)) {
          whatsappStatus.completed= true
        }else {
          whatsappStatus.completed= false
        }
        whatsappStatusArray.push(whatsappStatus)
      })
      commit('SET_WHATSAPPLIST', whatsappStatusArray)
    }
  },
  mutations: {
    SET_WHATSAPPLIST(state, payload) {
      return state.whatsAppStatusList = payload;
    }
  },
  state: {
    whatsAppStatusList: []
  }
}