<template>
<app-container title="About Scholarship" header="About Scholarship">
   <div class="pagecontent text-left">
      <img src="https://firebasestorage.googleapis.com/v0/b/everestfundraise.appspot.com/o/IATC%20Page%2Fabout%20IATC%20-%20Banner.jpg?alt=media&token=b2e230ca-479a-4c79-a92a-f8cb9c516ed2">
      <br><br>
      <p>'I am the change scholarship' is an initiative of Team Everest NGO through which we provide scholarship for single parented, parentless students and financially poor students to pursue graduation. </p>
      <p>We started this scholarship in 2016 and supported the graduation of 850 students till date. 80% of the students in this scholarship are girls.</p>
      <p>It costs us Rs 42,000 per student per year. Every student gets up to Rs 30,000 per year. Rest Rs 12,000 is used for their training program.</p>
      <p>For a student from a financially challenged background, not having money is just one of the challenge they face. They need just more than money. They need guidance. They need skills to survive in this world.</p>
      <p>That's why at Team Everest, every student gets up to 100 hours of training every year. The training is focussed on improving important career skills like communication skills, problem-solving skills, interview skills etc.</p>
      <p>We also help all our scholars to do Internship and help them by referring to corporates during their final year for jobs.</p>
      <!-- <img src="https://firebasestorage.googleapis.com/v0/b/everestfundraise.appspot.com/o/IATC%20Page%2FWhat%20student%20get.jpg?alt=media&token=72b0f1a7-3e44-422e-b534-17b44d907f95" alt="what student get ?"> -->
    
      <h5><strong>Videos: </strong></h5>
      <ul>
      <li><p>How to Shortlist students (1 Mins) - Click <a href="https://www.youtube.com/embed/Kq7oEAVKMdg" target="_blank">here</a> to watch the video.</p></li>
      <!-- <div class="embed-responsive embed-responsive-16by9">
        <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/Kq7oEAVKMdg" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>test</iframe>
      </div> -->
      
      <li><p>See what happens in our training program (1 Min) - Click <a href="https://www.youtube.com/embed/dVVqq9TquTc" target="_blank">here</a> to watch the video</p></li>
      <!-- <div class="embed-responsive embed-responsive-16by9">
        <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/dVVqq9TquTc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div> -->
      <li><p>See some of our student impact stories (1 Min per video) - Click <a href="https://youtube.com/playlist?list=PLzJxGiF44Zzr3ApKejRbJZAadX8WVsmJk" target="_blank">here</a> to watch the video</p></li>
      </ul>
      <!-- <div class="embed-responsive embed-responsive-16by9">
        <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/21PTv9e6cRY" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div> -->
      <br>
      <p>For more details on how this Scholarship works, check <a href="http://www.teameverest.ngo/scholarship" target="_blank">www.teameverest.ngo/scholarship</a></p>
      <div class="text-center">
       
        <a href="https://firebasestorage.googleapis.com/v0/b/everestfundraise.appspot.com/o/CTF-2021%2FNewBrochure%2FIATC%20Scholarship%20Donor%20Brochure.pdf?alt=media&token=9ffe0edb-37bd-43fa-8f2f-b2071e570fc0" target="_blank"> <v-btn color="#1A3263" dark tile>Download Brochure</v-btn></a>
         <!-- <a href="https://firebasestorage.googleapis.com/v0/b/everestfundraise.appspot.com/o/IATC%20Page%2FIATC%20-%20Brochure.pdf?alt=media&token=610082d7-a90c-4fc9-9280-04cb58722e9f" target="_blank"><button type="button" class="btn btn-lg button-inside">Download Brochure</button></a>  -->
      </div>
    </div>
</app-container>
</template>

<script>
import Container from '../../components/shared/Container.vue';
export default {
  components: {
        appContainer: Container,
    },
    
}
</script>

<style scoped>
img {
    max-width: 100%;
  }
  .pagecontent
  {
    padding: 2%;
    font-weight: 400;
  }
.button-inside {
  background-color: #0392cf;
  color:#E8E2DB;
  /* color: white; */
  }
  
</style>