<template>
  <app-container title="Transparency" header="Transparency">
    <div class="pagecontent text-left">
        <h2 style="padding-top: 2%; padding-bottom: 3%;"><strong>Commitment from<br>Team Everest</strong></h2>
      <p>Check out the below commitment from Team Everest. We love being transparent and build a community of volunteers who stand up for a cause.</p>
      <p><strong>1. Open and transparent Fundraising:</strong> Anyone who visits your fundraising page will know how much you have raised.</p>
      <p><strong>2. Know your donors:</strong> You can know who donated to your fundraising campaign by logging into your personalised fundraising page and going to 'Invite via Email' option. Your mobile number is your Login ID and you will get a OTP to login.</p>
      <p><strong>3. Join the Scholarship Award Ceremony:</strong> We shortlist students and award Scholarship to students during a ceremony. You, your friends or your donors can join this event in person if they are in Chennai, Coimbatore, Madurai, Bangalore, Mumbai or Pune. We will also do an online session where donors from any part of this world can meet our scholars.</p>
      <p><strong>4. Know your Student:</strong> We tag a student to all fundraisers, no matter how much funds you raise. That way you know who you are helping. Your donors are also notified about the student. This tagging of scholar happens in November or December every year.</p>
      <p><strong>5. Know what is happening in Scholarship:</strong> We send a quarterly update to all our fundraisers to keep them posted on what is happening in Scholarship and how we are creating an impact.</p>
      <p><strong>6. Social Media:</strong>We post regularly about our Scholarship events and stories in all our social media pages (<a href="https://www.facebook.com/TeamEverestNGO/" target="_blank">Facebook</a>, <a href="https://www.instagram.com/teameverestngo/" target="_blank">Instagram</a>, <a href="https://www.linkedin.com/company/teameverestngo">Linkedin</a>, <a href="https://twitter.com/teameverestngo" target="_blank">Twitter</a>). Follow us to know how your fundraising is making a difference.</p>
      <p><strong>7. You Tube Channel:</strong> We want all our donors to know our scholar stories. Follow our<a href="https://www.youtube.com/c/TeamEverestNGO" target="_blank"> youtube channel</a> to know what your donations mean to them.</p>
      <p><strong>8. Call or Meet your Student:</strong>You can call or meet the student you are sponsoring. Students will be from Chennai, Madurai, Coimbatore, Bangalore, Mumbai or Pune. You can meet the student along with a representative from Team Everest. We can also schedule audio or video call based on your and student availability.</p>
      <p><strong>9. Be part of Student Selection process:</strong> Your role does not just stop with fundraising. If you have time, you can be part of the our student selection process during April, May & June 2021.</p>
      <p><strong>10. Volunteer:</strong> Go beyond fundraising. No matter where you are. You can volunteer to train our students virtually on Spoken English and many more initiatives. Check here to know all virtual volunteering opportunities with Team Everest.</p><br>
      <p>You can know more about Team Everest transparency efforts in <a href="https://www.teameverest.ngo/transparency" target="_blank">www.teameverest.ngo/transparency</a></p>
    </div>
  </app-container>
</template>

<script>
import Container from '@/components/shared/Container.vue';
export default {
  components: {
        appContainer: Container,
    },
    
}
</script>

<style scoped>
 h2 {
    text-align: center;
  }

.pagecontent
  {
    padding: 2%;
    font-weight: 400;
  }
 

</style>