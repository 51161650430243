import * as fb from '@/common/config'
import router from '@/router'
export default {
    namespaced: true,
    getters: {
        getUserDetail: state => {
            return state.userDetails;
        },
        getPhoneNumber: state => {
            return state.phoneNumber;
        },
        getLoaderInfo: state => {
            return state.isLoading;
        },
        getBuddyDetails: state => {
            return state.buddyDetails;
        },
        getLoginOrSignupInfo: state => {
            return state.isLoginOrSignUp;
        },
        getEmailId: state => {
            return state.emailId;
        },
        getUserPhoneNumber: state => {
            return state.userPhonenumber;
        },
    },
    state: {
        phoneNumber: "",
        userDetails: null,
        buddyDetails: null,
        isLoading: false,
        isLoginOrSignUp: false,
        emailId: "",
        userPhonenumber: ""

    },
    actions: {
        async signup({
            commit,
            dispatch
        }, form) {
            commit('Set_LoaderStatus', true);
            commit('Set_LoginOrSignupStatus', true);
            try {
                const {
                    user
                } = await fb.auth.createUserWithEmailAndPassword(form.email, form.password)
                if (user.uid) {
                    try {
                        await fb.categoryMappingCollection
                            .doc(form.phoneNumber)
                            .set({
                                uid: user.uid,
                            }, {
                                merge: true
                            })
                        await dispatch('FetchUserDetail', {
                            uid: user.uid,
                            phoneNumber: form.phoneNumber,
                            email: user.email
                        })

                        return user;
                    } catch (err) {
                        commit('Set_LoaderStatus', false);
                        commit('Set_LoginOrSignupStatus', false);
                        if (err) {
                            throw {
                                code: "user not available"
                            }
                        } else {
                            throw {
                                code: "Something went wrong"
                            }
                        }

                    }

                } else {
                    commit('Set_LoaderStatus', false);
                    commit('Set_LoginOrSignupStatus', false);
                    throw "something went wrong"
                }
            } catch (err) {


                commit('Set_LoaderStatus', false);
                commit('Set_LoginOrSignupStatus', false);
                throw err
            }



        },
        async login({
            commit,
            dispatch
        }, form) {
            commit('Set_LoaderStatus', true);
            commit('Set_LoginOrSignupStatus', true);
            try {
                const {
                    user
                } = await fb.auth.signInWithEmailAndPassword(form.email, form.password)
                if (user.uid) {

                    await dispatch('FetchUserDetail', {
                        uid: user.uid,
                        email: user.email
                    })
                } else {
                    commit('Set_LoaderStatus', false);
                    commit('Set_LoginOrSignupStatus', false);
                    throw {
                        code: "user not available"
                    }
                }
                return user;
            } catch (err) {
                commit('Set_LoaderStatus', false);
                commit('Set_LoginOrSignupStatus', false);
                throw err
            }

        },
        async logout({
            dispatch
        }) {
            await fb.auth.signOut()
            dispatch('resetUserDetail')
        },
        async forgetPassword({
            dispatch
        }, payload) {
            try {
                await fb.auth.sendPasswordResetEmail(payload.email)
                return payload;
            } catch (err) {
                throw err;
            }

        },
        //buddyDetailsCollection
        async assignBuddyDetail({
            commit
        }, payload) {
            if (payload) {
                const buddydoc = await fb.buddyDetailsCollection.doc(payload).get();
                if (buddydoc.exists) {
                    const buddyDetails = buddydoc.data();
                    commit('Set_BuddyDetails', buddyDetails)
                }
            }
        },
        async FetchUserDetail({
            commit,
            getters,
            dispatch
        }, payload) {
            if (payload.email) {
                commit('Set_EmailId', payload.email);
            }
            commit('Set_LoaderStatus', true);
            let doc;
            let userDetails;
            const snapshot = await fb.categoryMappingCollection.where("uid", "==", payload.uid).get();
            if (snapshot.docs[0]) {
                if (snapshot.docs[0].id) {
                    commit('Set_UserPhuneNumber', snapshot.docs[0].id);
                }
                userDetails = snapshot.docs[0].data();
            }

            if (userDetails && userDetails.status === 'active') {
                commit('Set_UserDetail', userDetails)
                commit('Set_PhoneNumber', userDetails.phoneNo)
                await dispatch('whatsappstatustask/FETCHWHATSAPPSTATUSLIST', userDetails.postedWhatsAppStatus, {
                    root: true
                });
                await dispatch('diytask/FETCHDIYPOST', userDetails.completedDIY, {
                    root: true
                });
                await dispatch('whatsappask/FETCHWHATSAPPASKLIST', null, {
                    root: true
                });
                dispatch('assignBuddyDetail', userDetails.assignedBuddyId);
                // change route to dashboard
                commit('Set_LoaderStatus', false);
                commit('Set_LoginOrSignupStatus', false);
                if (router.currentRoute.path === '/login' || router.currentRoute.path === '/accessrequest') {
                    router.push('/')
                }
                return userDetails;
            } else {
                if ((userDetails && userDetails.status !== 'access pending') && payload.phoneNumber) {
                    await fb.categoryMappingCollection
                        .doc(payload.phoneNumber)
                        .set({
                            uid: payload.uid,
                            status: "access pending"
                        })
                }
                commit('Set_LoaderStatus', false);
                commit('Set_LoginOrSignupStatus', false);
                throw "document doesn't exist"
            }
        },
        async resetUserDetail({
            commit,
            getters,
            dispatch
        }, payload) {
            commit('Set_UserDetail', {});
            commit('Set_PhoneNumber', "")
            router.replace('/login')
        },
        async CompletedWhatsappStatus({
            commit,
            getters,
            dispatch
        }, payload) {
            commit('Set_LoaderStatus', true);
            const postedWhatsAppStatusList = getters.getUserDetail && getters.getUserDetail.postedWhatsAppStatus ? getters.getUserDetail.postedWhatsAppStatus : [];
            let postedWhatsAppStatus = [];
            if (payload.action === 'remove') {
                postedWhatsAppStatus = postedWhatsAppStatusList.filter(postedWhatsAppStatus => postedWhatsAppStatus !== payload.postId);
            } else {

                postedWhatsAppStatus = [...postedWhatsAppStatusList, payload.postId]
            }
            try {
                await fb.categoryMappingCollection
                    .doc(getters.getPhoneNumber)
                    .update({
                        postedWhatsAppStatus
                    })
                commit('Update_UserDetail', {
                    property: "postedWhatsAppStatus",
                    value: postedWhatsAppStatus
                })
                dispatch('whatsappstatustask/UPDATEWHATSAPPSTATUSLIST', postedWhatsAppStatus, {
                    root: true
                });
                commit('Set_LoaderStatus', false);
                return postedWhatsAppStatus;
            } catch (err) {
                commit('Set_LoaderStatus', false);
                throw err;
            }


        },
        //DIY Post
        async CompletedDIYPost({
            commit,
            getters,
            dispatch
        }, payload) {
            commit('Set_LoaderStatus', true);
            const postedDIYList = getters.getUserDetail && getters.getUserDetail.completedDIY ? getters.getUserDetail.completedDIY : [];
            let completedDIY = [];
            if (payload.action === 'remove') {
                completedDIY = postedDIYList.filter(completedDIY => completedDIY !== payload.postId);
            } else {
                completedDIY = [...postedDIYList, payload.postId]
            }
            try {
                await fb.categoryMappingCollection
                    .doc(getters.getPhoneNumber)
                    .update({
                        completedDIY
                    })
                commit('Update_UserDetail', {
                    property: "completedDIY",
                    value: completedDIY
                })
                dispatch('diytask/UPDATEDIYLIST', completedDIY, {
                    root: true
                });
                commit('Set_LoaderStatus', false);
                return completedDIY;
            } catch (err) {
                commit('Set_LoaderStatus', false);
                throw err;
            }

        },
    },

    mutations: {
        Set_UserDetail(state, payload) {
            return state.userDetails = payload;
        },
        Update_UserDetail(state, payload) {
            return state.userDetails[payload.property] = payload.value
        },
        Set_PhoneNumber(state, payload) {
            return state.phoneNumber = payload;
        },
        Set_LoaderStatus(state, payload) {
            return state.isLoading = payload;
        },
        Set_LoginOrSignupStatus(state, payload) {
            return state.isLoginOrSignUp = payload;
        },
        Set_BuddyDetails(state, payload) {
            return state.buddyDetails = payload;
        },
        Set_EmailId(state, payload) {
            return state.emailId = payload;
        },
        Set_UserPhuneNumber(state, payload) {
            return state.userPhonenumber = payload;
        }
    },
}