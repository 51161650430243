<template>
<app-container title="About Team Everest" header="About Team Everest">
   <div class="pagecontent">
      <h2 class="text-center" style="padding-bottom:3%; padding-top:3%;">About Team Everest</h2>

      <p class="text-left">Team Everest started its journey in August 2006 when founder Karthee Vidya completed graduation and joined his first job. He donated from his first month salary and collected funds from 3 of his friends to raise Rs 4000 to help children in a rural school in Tamilnadu. We used the funds to buy books and uniforms for the school kids there.</p>

      <p class="text-left">After working in an IT company for 8 years, he quit his high paying job in 2014 to work full time on Team Everest.</p>

      <p class="text-left">What started as a small activity, has taken wings over the last 14 years. Today, we work with 40,000 children a year with help from 25,000 proud Volunteers.</p>

      <p class="text-left">Scholarships, Spoken English, Digital literacy, Soft Skill classes are some of our key initiatives.</p>
      
      <p class="text-left">Team Everest is a registered trust in India with 80G certification. All donations made to Team Everest gets 50% tax exemption from Government of India.</p>

      <p>Know more about us in <a href="https://www.teameverest.ngo/" target="_blank">www.teameverest.ngo</a></p>
   <div class="text-center">
      <a href="https://firebasestorage.googleapis.com/v0/b/everestfundraise.appspot.com/o/CTF-2021%2FNewBrochure%2FTeam%20Everest%20Brochure.pdf?alt=media&token=b0bde99e-6b96-4e70-965d-44a33c46d302" target="_blank"> <v-btn color="#1A3263" dark tile>Download Brochure</v-btn></a>
    <!-- <a href="https://firebasestorage.googleapis.com/v0/b/everestfundraise.appspot.com/o/About%20Team%20Everest%2FTeam%20Everest%20Brochure.pdf?alt=media&token=e0bc7954-1d81-44ff-98b5-86e4b1b5df29" target="_blank"><button type="button" class="btn btn-lg button-inside">Download Brochure</button></a>  -->
   </div>
    </div>
</app-container>
</template>

<script>
import Container from '@/components/shared/Container.vue';
export default {
  components: {
        appContainer: Container,
    },
    
}
</script>

<style scoped>
.pagecontent
  {
    padding: 2%;
    font-weight: 400;
  }
.button-inside {
  background-color: #0392cf;
  color:#E8E2DB;
  /* color: white;   */
  }

</style>