<template>
  <app-container title="Whatsapp Ask">
    <app-snackbar v-model="showSnackbar" :text="snackbarText"></app-snackbar>
    <app-chips :chips="chips" v-model="selected"> </app-chips>
    <hr v-if="selected === 'Follow Up'" />
    <app-chips
      v-if="selected === 'Follow Up'"
      :chips="subchips"
      v-model="subselected"
    >
    </app-chips>
    <hr />
    <div v-if="whatsappAskDetail">
      <app-list-with-heading
        headerText="What to do:"
        :listWithoutDot="true"
        :descriptions="whatsappAskDetail.whatToDo"
      >
      </app-list-with-heading>
      <app-img-download
        v-for="whatsappaskimg in whatsappAskDetail.images"
        :image="whatsappaskimg"
        :hideDownload="true"
        :key="whatsappaskimg"
      >
      </app-img-download>
      <br />
      <!-- <app-list-with-heading
        :descriptions="whatsappAskDetail.howToDo"
        :hideLine="true"
      >
      </app-list-with-heading> -->
      <div v-if="whatsappAskDetail.howToDoList">
        <app-copy-text
          v-for="howToDo in whatsappAskDetail.howToDoList"
          :howToDo="howToDo"
          :copyText="copyText"
        ></app-copy-text>
        <div v-if="whatsappAskDetail.donationLinkCopyText">
          <div
            v-html="
              whatsappAskDetail.donationLinkText +
                userDetail.donationLink +
                '</p>'
            "
          ></div>
          <div
            class="cpy-container"
            @click="copyDonationLink"
            v-clipboard:copy="
              whatsappAskDetail.donationLinkCopyText + userDetail.donationLink
            "
          >
            <v-btn class="cpy-chip" color="#1A3263" small text>
              <span class="cpy-text"> Copy Text</span>
              <font-awesome-icon icon="copy" />
            </v-btn>
          </div>
        </div>
      </div>

      <div v-else>
        <div class="postcontent" v-html="whatsappAskDetail.howToDo"></div>

        <div
          class="cpy-container"
          @click="copyDonationLink"
          v-clipboard:copy="whatsappAskDetail.clipboardText"
        >
          <v-btn class="cpy-chip" color="#1A3263" small text>
            <span class="cpy-text"> Copy Text</span>
            <font-awesome-icon icon="copy" />
          </v-btn>
        </div>
      </div>
    </div>
  </app-container>
</template>

<script>
import { mapGetters } from "vuex";
import Container from "@/components/shared/Container.vue";
import Chips from "@/components/chips/chips.vue";
import ListWithHeading from "@/components/shared/ListWithHeading.vue";
import ImgDownload from "@/components/img-download/img-download.vue";
import Snackbar from "@/components/shared/Snackbar.vue";
import Dialog from "@/components/shared/Dialog.vue";
import CopyText from "@/components/shared/CopyText.vue";

export default {
  computed: {
    ...mapGetters({
      userDetail: "user/getUserDetail",
    }),
    whatsappAskDetail() {
      if (this.selected === "Ask") {
        return this.$store.getters["whatsappask/getWhatsppListAsk"];
      } else if (this.selected === "Follow Up") {
        if (this.subselected === "Interested - Yet to donate") {
          return this.$store.getters[
            "whatsappask/getWhatsppListFollowUpDonate"
          ];
        } else if (this.subselected === "Yet to reply") {
          return this.$store.getters["whatsappask/getWhatsppListFollowUpReply"];
        }
      } else if (this.selected === "Thank You") {
        return this.$store.getters["whatsappask/getWhatsppListThankyou"];
      }
      return {};
    },
  },
  data: () => ({
    showSnackbar: false,
    snackbarText: "",
    chips: ["Ask", "Follow Up", "Thank You"],
    subchips: ["Interested - Yet to donate", "Yet to reply"],
    selected: "Ask",
    subselected: "Interested - Yet to donate",
  }),
  props: {
    footerText: {
      type: String,
      default: "© Team Everest 2020 v1.6.0",
    },
    goBack: {
      type: Function,
    },
    content: {
      type: String,
      default: "Title",
    },
  },
  methods: {
    copyDonationLink() {
      this.showSnackbar = true;
      this.snackbarText = "Copied Text";
    },
    onScroll(ev, position) {},
    copyText() {
      this.showSnackbar = true;
      this.snackbarText = "Copied Text";
    },
  },
  components: {
    appContainer: Container,
    appChips: Chips,
    appListWithHeading: ListWithHeading,
    appImgDownload: ImgDownload,
    appSnackbar: Snackbar,
    appDialog: Dialog,
    appCopyText: CopyText,
  },
};
</script>

<style scoped>
.cpy-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.cpy-chip {
  background-color: #1a3263 !important;
  color: #e8e2db !important;
}
.cpy-text {
  margin-right: 10px;
}
</style>
